// src/pages/History.js

import React from 'react';

const History = () => {
  return (
    <div>
      <div className='main'>
        <div className="column1"></div>
        <div className="column2">
          <div style={{ textAlign: 'center' }}>
            <h1>History</h1>
          </div>
          <p>
            Sabrenetics is the world's first cyberware and cyborg clinic for research, standardisation, education, availability and support on Cyberware, Cyborgs and Transhumans.
          </p>
          <h3>Notice</h3>
          <p>
            We are not a healthcare provider. We do not provide medical support. We do not employee any qualified medical board practitioners. Scientific concepts discussed within this website may not be accurate. The information presented here is research and education to the best of our knowledge and abilities. We advise you to do your own research on any concepts discussed in this website. We do not condone repeating ideas presented in this website without professional guidance.
          </p>
          <h3>History</h3>
          <p>
            <div style={{ textAlign: 'center' }}>
              Updated: 07/03/2024
            </div>
          </p>
          <p>In 1954 Qian Xuesen also known as Hsue-Shen Tsien (Chinese: 钱学森) (Born: 11th. December, 1911 CE - Death: 31st, October, 2009) a Chinese astrophysicist, aerospace engineer, mathematician, professor and cyberneticist a member of the Manhattan Project along with Albert Einstein, notable for worldwide nuclear technology proliferation and the foundation of the Chinese space agency was placed under prolonged house arrest in the United States of America under false pretence of communist sympathies. </p>
          <p>The allegation was never substantiated. Qian fearing his livelihood eventually ran to safety in China. Before departing the USA his monograph (a scholarly book on a specific subject) on control theory and guided systems named "Engineering Cybernetics" was published. This innovative research laid the foundation for the discipline of "Engineering Cybernetics," encompassing the knowledge and practical application of cybernetics in designing controlled and guided systems, similar to robotics and control engineering.</p>
          <div style={{ textAlign: 'center' }}>
            <img src="/assets/graphics/pages/history/qian_xuesen.jpg" alt="Qian Xuesen drawing on blackboard" style={{ maxWidth: '100%', height: 'auto' }} />
            <p>Figure 1. Undated photo taken of multi-disciplined scientist and professor Qian Xuesen (錢學森).</p>
          </div>
          <p>Cybernetics has been ill defined throughout our history. The Etymology (history) of the word dates back to ancient Greek terminologies kybernētikḗ (Greek: κυβερνητική), meaning "governance", kybérnēsis (Greek: κυβέρνησις), meaning "government", while  kybernḗtēs (Greek: κυβερνήτης) is the governor, pilot, or "helmsperson" of the "ship". The word cybernetics was first used in the context of "the study of self-governance" by Plato in his works titled "Republic" (around 375 BCE). </p>
          <p>French physicist and mathematician André-Marie Ampère first coined the word "cybernetique" in his 1834 essay ''Essai sur la philosophie des sciences'' to describe the science of civil government. </p>
          <p>During the Macy conferences (1941 - 1960) a series of conferences held in New York under the direction of Frank Fremont-Smith at the Josiah Macy Jr. Foundation starting in 1941 and ending in 1960, in which like-minded thinkers of sciences and philosophies gathered to define the meanings of various ideas it was during a certain conference that Cybernetics was characterised as the study of "circular causal and feedback mechanisms in biological and social systems". </p>
          <p>Initially the terminology defined the study of circular causality in a feedback loop, the scientific study of feedback mechanisms in a circular system. A system of any nature (machine or biological) runs, an external environmental stimulus then interacts or enters with the loop system then the system responds or adapts to the new stimulus and changes.  </p>
          <p>Over time the meaning of cybernetics has gone to encompass many disciplines and fields from civil governance e.g., politics but evolved to deal with the scientific study of communication and governance in society and humans. Overtime the meaning extended to include the study of machine communication and governance. </p>
          <p>One of the most well-known inclusive definitions of cybernetics is that of Norbert Wiener (1894 – 1964) in his book titled Cybernetics (1948), he identified cybernetics as concerned with "control and communication in the animal and the machine". Cybernetics can be viewed as an approach to systems analysis in biological and machine systems with governance, control, and communication variables. </p>
          <p>Cybernetics is a systems analysis and modelling approach. A system of any nature such as biological (the human heart), or artificial (a car) are modelled and analyzed based upon its inputs, behavior of integration of the system with these inputs, the outputs, the behavior of the outputs upon the system, and recursively looking at the system again, and through various lenses studied and analyzed based upon various discipline fields.  </p>
          <p>The hyphenation or inclusion of the word cybernetics with another discipline e.g., engineering cybernetics, medical cybernetics, or technological cybernetics changes the meaning to be the study, science and technology related to cybernetics theory of governance, communication, control, in relation to the discipline. The word "engineering cybernetics" therefore refers to the understanding and application of governance, communication, control, and convergence in relation to man and machinery systems analysis within engineering.</p>
          <p>The goal of Cybernetics is to understand the governance, communication, and control, of a biological or artificial system, using different variables, such as inputs, outputs, changes, environmental stimuli etc., through the lenses of different scientific fields. The idea of cybernetics is to apply a universal method for analysing different systems, whether it be political, scientific, philosophical or any natural or abstract concept. Cybernetics can be understood as "critical thinking".</p>
          <div style={{ textAlign: 'center' }}>
            <p>Cybernetics (noun): is a transdisciplinary approach of systems analysis exploring governance, control, communication, and convergence of biological & machine systems.</p>
            <p>Example 1: "Cybernetics is an alternative method to Control Theory to measure the degree or articulation an prosthetic arm can move, and to reduce it till it remains in goal with the wearers natural range of motion.</p>
            <p>Using cybernetics approach to systems analysis by drawing the systems process of range of movement, and setting a goal of articulation, different prosthesis controllers can be programmed and configured to reach the desired state using mathematics, engineering, physics, biology, and philosophical viewpoints to achieve natural motion and the wearers satisfaction with their prosthetic.</p>
            <p>Example 2: " Cybernetics can be used compare different systems such as; the study of Human Government, Artificial Intelligence, Bionic prosthetic limbs, Brain-Computer Interfaces or Cyborgs to find out public perceptions.</p>
            <p>Using cybernetics approach to systems analysis by drawing different systems, their inputs, process, outputs, and environmental variables and stimuli, we can observe where human inputs and satisfaction are derived from when interacting with each system, then study and tabulate the results to find conclusions.</p>
            <p>Example 3: "Cyborg; a portmanteau of the words cybernetic and organism, is an organism with biomedically engineered devices (which were discovered from experiments using cybernetics, medical implants and human compatibility) known as "Cyberware" joined together, such as robotic camera eyes in replacement for their pre-existing eyes or bionic prosthetic limbs that are controlled with a brain-computer interface governed by the hosts brain." </p>
            <img src='/assets/graphics/pages/history/cybernetics_feedback_loop_models.png' alt="Cybernetics feedback loop models" style={{ maxWidth: '100%', height: 'auto' }} />
            <p>Figure 2. Two diagrams representing the original and improved models of a Cybernetics Feedback loop. The first top diagram shows the results of a change in a closed loop system being observed first introduced in the Macy Conference discussions by a panel conclusion by multiple experts, whereas the new model introduced in the 2nd order Cybernetics conference in continuation of the Macy Conferences below diagram below shows a second level of observation of the results of the original study.  </p>
          </div>
          <p>As Cybernetics is the explorative approach to nouveau biological and machine systems, new discoveries and subsequent innovations are found to improve functionalities in existing biological systems. The technological and scientific discovery improvements mean the convergence of all these sciences fields can be used to improve the biological self. In example cybernetics is used to explore biocompatibility of engineered medical devices and humans.</p>
          <p>In essence by exploring the improvements in biological systems with the viewpoint from existing sciences and philosophies we’ve come to discover and categories artificial intelligence, complex algorithms, better machine designs, a better understanding of how machines and systems can interface with biology, and beyond. From exploring existing systems do we find new science discoveries. </p>
          <p>The technology that’s come about from these explorative approaches from the converged fields of medical/engineering/technological cybernetics is known as Brain-Computer interfaces which is also known as Cyberware. BCI’s or Cyberware can be used to recreate lost biological functionality, help with rehabilitation, prevent further injuries through improving kinetic and mental output. </p>
          <p>Cyberware combines the art of everything from Bionics, Brain-Computer Interfaces, Implants, Neural Prosthesis and Prosthetics to create hybrid humans that have restored functionality using computerized technology and to also cosmetically function better then through natural birth by means of altering physiology to greater senses, cognitive and physical functions and heights. </p>
          <p>The new technology convergence discovered into human beings can help promote, functionality, rehabilitation and improvement can be used to improve the lives of humans through negating negative health constraints such as natural death of cells and create improvements in life via improving biological neural cognitive and kinetic physical function by providing an internalized or external medical device apparatus that’s integrated through augmentation to the hosts biological self. </p>
          <p>Examples of Cyberware can include </p>
          <li>Neural/Brain Computer Interfaces: These aid in diagnosing biological issues through electrophysiology and medical imaging related to the brain.</li>
          <li>Bionics: This category encompasses prosthetic limbs designed to restore function, hearing aids, pacemakers for regulating heart rhythm, and exoskeletons assisting in mobility.</li>
          <li>Medical Implants: Ranging from diabetic insulin auto-injection systems to contraceptive implants, these implants serve various medical purposes, enhancing and maintaining health."</li>
          <p>Cyberware isn't limited to the human self but can endeavour the creation of everything from medical & technological convergence from nanorobotics through to nootropics (cognitive enhancers). The possibilities are endless as our technology jumps further ahead. </p>
          <p>The benefits lie in changing humanity to be free of ill and attain immortality from natural pathological causes. Society can be changed by connecting us all together through neural synapses giving humanity the mental attainment of unity. Cyberware is the link to human perfection to be unified, one, together and never alone. To be better, faster, stronger, smarter, feel in control, be ambulatory and work and talk to those we love instantaneously. </p>
          <p>Scientists known as biomedical engineers whom engineer and work together with other healthcare professionals to implement biomedical engineered solutions, as well as their unqualified and regulated counterparts Individuals known as biohackers, have drawn influence from cybernetics, medicine and science fiction media to create biomedical engineered technology with electronics and metal known as cyberware to merge man and machine known as cyborg a portmanteau (combination) of words cybernetic and organism. Subsequent individuals began attempting basic cursory unsafe and unapproved implants. In the medical fields multiple advancements of Brain-Computer interfaces, Bionics, Prosthetics and Implants of functional and cosmetic nature have come to become norm in society. </p>
          <p>Cyborgs walk among us, but they're not the stereotypical, science fiction portrayals of menacing, metal-clad weaponised human hybrids with supernatural abilities. Instead, they're individuals who've integrated cyberware (biomedically engineered devices) for rehabilitation, disability assistance, and the integration of gadgets to improve everyday life. Becoming a cyborg is open to anyone, but the path to becoming a transhuman, blurring the lines between man and machine, is a journey we all undertake. This journey is evident in our society's ubiquitous use of technology for survival, the metaphorical attachment of mobile phones to our hands, and our reliance on technology to connect through social media. In essence, we are all cyborgs.</p>
          <p>However, there are those that choose to take a step further in the evolutionary chain then others, to implant gadgets, electronics, gizmos, into their bodies for cosmetic non rehabilitative/medical reasons. They choose to become something different, a transhuman. Humans that have become Cyborgs that are on their way to new paths of untraversed human potential. These are the Cyborgs we see in science fiction. These are the new breed of mankind. As artificial humans such as artificial intelligence, androids and cyborgs arise in mainstream society, so does the need for guidance on social aspects.</p>
          <p>Despite the advancements made in the field from a medical science perspective, the philosophical ideas of cyborgs, cyberware, and their effects on society, humans, and the blend between science and fiction has caused a gap to form. The knowledge required has become a barrier to progression, as well as governments ill-advised attempts to adopt new standardization for body autonomy laws on cyberware leading to biohackers, persons who create or implement unregulated biomedical engineering technologies arising.</p>
          <p>To stop the unregulated market of cyberware being created and implanted, and to create a framework for safety guidelines and standards for education, training and law an authority organisation was needed to oversee the creation of such deliverables. </p>
          <div style={{ textAlign: 'center' }}>
            <img src='/assets/graphics/pages/history/cyborgisation_flowchart.png' alt="Cyborgisation relationship flowchart" style={{ maxWidth: '100%', height: 'auto' }} />
            <p>Figure 3. The Biomedical Engineering sciences and philosophies relationship flowchart represents the relationship between various etymology, scientific disciplines and biology to draw the relationship between cybernetics, cyberware and cyborgisation. </p>
          </div>
          <p>On November 9th 2021 Sumeet Singh incorporated the world's first Cybernetics, Cyberware and Cyborg clinic, an all encompassing biomedical engineering organisation named Sabrenetics. A universal platform, community and organisation for the open source driven research, education, development of products and support on biomedical engineering related sciences and philosophies ranging from, cyberware, cyborgs and trans/post humanism.</p>
          <p>Born September 18th 1991 in Sydney Australia, from Fijian-Indian background his journey began at a young age with his fascination of tattoos, body modification, technology and science fiction. He was influenced by media such as Akira (1988), Shadowrun (1994), and Gattaca (1997). He had various piercings and tattoos as early as he was allowed to get them.</p>
          <p>In the early 2010's a chance meeting with Steve Haworth (a pioneer and spokesperson on subdermal body modification) in a Sydney tattoo & piercing parlor occurred leading to him getting his first implant a subdermal magnet in his left ring fingertip. From his newfound experience learning of other biohackers and organisations he further went on to receive many more including his own transdermal implants.</p>
          <div style={{ textAlign: 'center' }}>
            <img src='/assets/graphics/pages/history/sumeets_xray.png' alt="Xray of Sumeet Singh's left hand" style={{ maxWidth: '100%', height: 'auto' }} />
            <p>Figure 4. A X-ray image of Sumeet Singh's left hand taken sometime around January 2022 at Auburn Healthcare Centre, 4A Auburn Rd, Auburn, NSW, 2144, Australia. showing the presence of 2 subdermal implants. A Magnet under the left ring finger and RFID implant within the thumbs webbing.</p>
          </div>
          <p>A series of events of risky self biohacking procedures and hearing news of unsafe subdermal implant procedures leading to disfigurement and death and concerned with medical facilities lack care on his implants led to deciding to document his findings online for others.</p>
          <p>Documenting his work on Wikipedia leading to the foundation of Wiki knowledge on the topic, it became an obsession from which he dedicated his passion to discovery, documentation and innovation of the biomedical engineering and related scientific and philosophical field, landscape, and history</p>
          <p>Searching for information on biohacking provided no results for board qualified medical practitioners to cosmetically as well as functionally for rehabilitation apply cyberware. He found the space was filled with deceitful nature, nationalism, bigotry, egotism or other bias, boastful fallacies, hobby biohackers with unorganised documentation, and small time commercial ventures focusing on specific parts of cyberware, but never a universally; coherent, trustworthy, unified, safe, or accessible community.</p>
          <p>A criteria was created in order to find a credible source of knowledge and practice to contribute to.</p>
          <li>Organisations that have cosmetic biomedically engineered devices known as cyberware for public availability.</li>
          <li>A place of education on all matters of biomedical engineering sciences and philosophies from cyborgs, transhumanism to posthumanism.</li>
          <li>A place where ideas can be put forward by the public to engage in research, creating standards and documentation on the aforementioned sciences and philosophies.</li>
          <li>Organisations with a diverse cast of different sexualities, religions, cultures, and nationalities whom are spectators, participants, contributors, accreditors, or award recipients.</li>
          <li>A easily accessible public place of community and support on caring for cyborg and transhuman individuals.</li>
          <p>After years of research on cybernetics, cyberware and cyborgs and discussing and learning from various experts within the disciplines he noticed many questions remained unanswered, the space filled with bias, and medical boards refusals to accept new standards.</p>
          <p>It was time to start a new organisation that would encompass cybernetics, cyberware and cyborgs in its entirety. To be able to shape the discipline in a community driven way to be open source, have unrestricted information access, a place of research, education, congregation, and provide safe support cybernetics, cyberware and cyborg services to the global audience.</p>
          <p>Sabrenetics provides an equal field that anyone can be introduced, learn, be supported, research and experiment on biomedical engineering and related sciences and philosophies. Sabrenetics provides an freely accessible, ease of access, safety guided, knowledge repository and community of likeminded individuals with aims to empower everyone along their own journeys of learning and self discovery and document the ever evolving landscape of biomedical engineered technologies and philosophies. </p>
          <p>It was the inactivity, objectionable nature, inadequate or malicious intent of medical practitioners, boards, and governments to adopt or advocate universal safety standards and body autonomy laws that led to the regression of technological adoption that's research could have benefited the lives of countless, and prevented deaths caused by unregulated, unsafe and cursory biohacking procedures.</p>
          <p>Sabrenetics became the world's first cyberware and cyborg clinic for research, standardisation, education, availability and support on Cyberware, Cyborgs and Transhumans.</p>
          <h2>Learn More</h2>
          <p>The Ship of Theseus is a hypothetical situation in which if all parts of an object are replaced is it still the same object. Cyborgisation involves the augmentation of the human body with cybernetic parts which are biological engineered machinery known as Cyberware. Foreign objects are added and augmented within the living body in a process to morph the being for cosmetic and rehabilitative reasons transforming them into a hybrid cybernetic organism known as a Cyborg.</p>
          <p>The process of cyborgisation realizes itself to reality as mankind's technological limitations are surpassed. There are philosophical questions to ask of which are human beings of cybernetic makeup still the sum of their whole, still human or cyborg. What percentage of foreign matter is required to create a cyborg or what material constitutes cyberware parts such as silica based reading glasses, hybrid metal and plastic hearing aids, carbon and wooden based prosthetic legs, or even metal within pacemakers.</p>
          <p>All these questions are answered in the makeup of one's mind. The journey to cyborgisation or human perfection, evolution is an ongoing spectrum of continuous modifications. As beauty, cyborgisation has no limits and perfection has no boundaries. Is cyberware the right choice then in a life that one can never attain a perfect being. The answers lie in the journey. Without taking the first step to experience another life, a new world, a new being you cannot appropriately judge the existence.  </p>
          <p>Sabrenetics invites you to join the next stage of human evolution and become more than human. </p>
          <p>To learn more about the science of medical, engineering, technological, philosophy cybernetics, cyberware and cyborgisation we have come up with a free library you may browse here.</p>
          <p>Or view our calendar of events to interact with us in person, or online, in our events page here.</p>
        </div>
        <div className="column3"></div>
      </div>
    </div>
  );
};

export default History;
